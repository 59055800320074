import React, { PropsWithChildren } from "react";
import { RouteComponentProps } from 'react-router';
import cn from 'classnames';
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CompanyChannel from "services/channels/company.channel";
import Footer from "layouts/common/Footer/Footer";
import HomeNavbar from "./Navbar/HomeNavbar";
import Sidebar from "./Sidebar/Sidebar";

import { styles } from "./homeStyle";


interface IHomeLayoutProps extends WithStyles<typeof styles> {
  history: RouteComponentProps['history'];
  adminAccessAllowed: boolean;
  minimizedSidebar: boolean;
  setMinimizedSidebar: (flag: boolean) => void;
  logOut: () => void;
}

interface IHomeLayoutState {
  sidebarOpen: boolean;
  minimizedSidebarByDefault: boolean;
}

class HomeLayout extends React.Component<PropsWithChildren<IHomeLayoutProps>, IHomeLayoutState> {
  private unsubscribe: () => void;

  state = {
    minimizedSidebarByDefault: window.innerWidth < 1900, // minimize sidebar by default on small screens; only for desktop
    sidebarOpen: false  // only for mobile
  };

  componentDidMount() {
    this.unsubscribe = this.props.history.listen(this.onRouteChange);
    CompanyChannel.listen();
  }

  componentWillUnmount() {
    this.unsubscribe();
    CompanyChannel.stopListening();
  }

  render() {
    const { classes, children, minimizedSidebar, adminAccessAllowed, logOut } = this.props;
    const { sidebarOpen, minimizedSidebarByDefault } = this.state;
    const actuallyMinimizedSidebar = minimizedSidebar ?? minimizedSidebarByDefault;

    // Note! This layout behaves quite differently on desktop and mobile:
    // some properties and callbacks are for desktop, some - for mobile
    return (
      <div className={classes.wrapper}>
        <Sidebar
          brandText="Repair-it"
          open={sidebarOpen}
          adminAccessAllowed={adminAccessAllowed}
          isMinimized={actuallyMinimizedSidebar}
          onToggle={this.toggleSidebar}
        />
        <div className={cn(classes.main, actuallyMinimizedSidebar && classes.mainWithFixedSidebar)}>
          <HomeNavbar
            minimizedSidebar={actuallyMinimizedSidebar}
            logOut={logOut}
            toggleSidebarMinimized={this.toggleSidebarMinimized}
            toggleSidebar={this.toggleSidebar}
          />
          <main className={classes.content}>
            {children}
          </main>
          <Footer fluid />
        </div>
      </div>
    );
  }

  private toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  };

  private toggleSidebarMinimized = () => {
    const { minimizedSidebar, setMinimizedSidebar } = this.props;
    const isMinimizedNow = minimizedSidebar ?? this.state.minimizedSidebarByDefault;

    setMinimizedSidebar(!isMinimizedNow);
  };

  private onRouteChange = () => {
    this.setState({
      sidebarOpen: false
    });
  }
}

export default withStyles(styles)(HomeLayout);
