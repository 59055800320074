import React from "react";
import cn from "classnames";

import { WithStyles, withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { styles } from "./footerStyle";


interface IFooterProps extends WithStyles<typeof styles> {
  white?: boolean;
  fluid?: boolean;
  minimal?: boolean;
  noBorder?: boolean;
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const { fluid, white, minimal, classes, noBorder } = props;
  const container = cn({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  const link = cn({
    [classes.link]: true,
    [classes.whiteColor]: white
  });

  return (
    <footer className={cn(classes.footer, noBorder && classes.noBorder)}>
      <div className={container}>
        { !minimal && (
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a href="https://www.repairitroof.com/" target="_blank" rel="noopener noreferrer" className={link}>
                  Website
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="https://www.repairitroof.com/services" target="_blank" rel="noopener noreferrer" className={link}>
                  Services
                </a>
              </ListItem>
            </List>
          </div>
        )}

        <p className={minimal ? classes.center : classes.right}>
          © {new Date().getFullYear()} by&nbsp;
          <a href="https://www.repairitroof.com" target="_blank" rel="noopener noreferrer" className={classes.copyrightLink}>
            Repair-it Roofing
          </a>
        </p>
      </div>
    </footer>
  );
};

export default withStyles(styles)(Footer);
